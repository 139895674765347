import { usePrivate } from 'Adapters/Identity'
import { FC } from 'react'
import { Container } from './Container'
import { ErrorDisplay } from './ErrorDisplay'
import { Loaded } from './Loaded'
import { Loading } from './Loading'

export const WorldList: FC = () => {
    const { allowed } = usePrivate()
    return allowed ? (
        <Container
            loading={<Loading/>}
            error={<ErrorDisplay/>}
            loaded={<Loaded />}
        />
    ) : null
}