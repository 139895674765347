import { gather } from 'API/utils'
import { QueryStatus } from '../types'
import { worldListAdapter } from './domain/adapter'
import { Data, GetWorldList } from './types'

export const getWorldList: GetWorldList = async () => {
    const data = await gather<Data>('get-world-list')
    const value = worldListAdapter({ data })

    return value ? {
        status: QueryStatus.Success,
        value: value,
    } : {
        status: QueryStatus.Error,
        value: undefined,
    }
} 