import { FC } from 'react'
import typeStyles from 'Theme/typography.module.css'
import { IconButton } from 'Comps/Button/Icon'
import s from './styles.module.css'
import { cn } from 'utils'

export const CreateHeader: FC = () => (
    <header className={cn(s['create-header'])}>
        <h1 className={typeStyles['heading-primary']}>Create</h1>
        <div>
            <IconButton name="Sort" />
            <IconButton name="Apps" />
            <IconButton name="Search" />
        </div>
        <p>What do you to make?</p>
    </header>
)
