import { Icon } from 'Comps/Icon'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import s from './styles.module.css'

export const AppHeader: FC = () => (
    <header className={s['app-header']}>
        <NavLink to="/"><Icon name="Apps" /></NavLink>
        <NavLink to="/">Talltale</NavLink>
        <NavLink to="/road-map"><Icon name="OrderedList" /></NavLink>
    </header>
)