import {
    Throttle,
    TrimString,
    AddOrRemove,
    UnknownFunc,
} from './types'

export const debounce = <T extends UnknownFunc>(
    func: T,
    delay: number,
    immediate = false,
) => {
    let inDebounce: number
    let called = false
    return (...args: Parameters<T>) => {
        if (immediate && !called) {
            called = true
            func(...args)
        }
        if (inDebounce) window.clearTimeout(inDebounce)
        inDebounce = window.setTimeout(() => func(...args), delay)
    }
}

export const throttle: Throttle = (func, delay, immediate = true) => {
    let throttled: boolean
    let called: boolean
    return (...args) => {
        if (throttled) return
        if (immediate && !called) {
            called = true
            func(...args)
        }
        throttled = true
        window.setTimeout(() => {
            func(...args)
            throttled = false
        }, delay)
    }
}

export const trimString: TrimString = (original, length, suffix) =>
    original.length > length
        ? `${original.slice(0, length).trim()}${suffix}`
        : original

export const addOrRemove: AddOrRemove = (arr, value) =>
    arr.includes(value) ? arr.filter(v => v !== value) : [...arr, value]

export const replaceTokens = (
    text: string,
    map: Array<[string, string]>,
): string =>
    text.replace(
        new RegExp(map.map(t => `\\${t[0]}`).join('|'), 'gi'),
        match => (map.find(t => t[0] === match) || ['', ''])[1],
    )

export const waitForDelay = (delay: number): Promise<void> =>
    new Promise(resolve => setTimeout(() => resolve(), delay))

export const rmWhite = (str: string) => str.replace(/^\s+/gm, '')

export const blankArray = (length: number): undefined[] => Array(length).fill(undefined)

export const numBetween = (min: number, max: number): number => ~~(Math.random() * (max - min + 1) + min)

export const noop = () => {}

export const validateEmail = (input: string): boolean => (new RegExp(/\S+@\S+\.\S+/)).test(input)

export const cn = (...classNames: string[]): string => classNames.join(' ')
