import { FC } from 'react'
import bg from './assets/word-image.jpg'
import s from './styles.module.css'

export const TaleCard: FC = () => {

    return (
        <div className={s['tale-card']}>
            <img src={bg} height={300} width={300} alt="word game" />
        </div>
    )
}
