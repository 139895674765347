import { FC } from 'react'
import s from './styles.module.css'
import { Role, usePrivate } from 'Adapters/Identity'
import { TextEditor } from 'Comps/TextEditor'

export const Sandbox: FC = () => {
    usePrivate([Role.Dev ])

    return (
        <div className={s['sandbox']}>
            <TextEditor onChange={() => {}}/>
        </div>
    )
}
