import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useContainer } from '../Container'

export const Loaded: FC = () => {
    const { worldList } = useContainer()

    return (
        <div>
            <header>
                <h1>Worlds</h1>
            </header>
            <section>
                <nav>
                    { worldList.worlds.map(world => (
                        <Link key={world.id} to={world.id}>
                            <p>{ world.name }</p>
                        </Link>
                    )) }
                </nav>
            </section>
        </div>
    )
}