import { useCallback } from 'react'
import { Editor } from 'slate'
import { toggleBold, toggleItalic, toggleStrike } from './actions'
import { KeyDownHandlers } from './types'

export const useHandlers = (editor: Editor) => {
    const ctrlKeyDownHandlers: KeyDownHandlers = {
        'b': useCallback(e => {
            console.log('hello b')
            e.preventDefault()
            toggleBold(editor)
        }, [editor]),
        'i': useCallback(e => {
            console.log('hello i')
            e.preventDefault()
            toggleItalic(editor)
        }, [editor]),
        'd': useCallback(e => {
            console.log('hello d')
            e.preventDefault()
            toggleStrike(editor)
        }, [editor]),
    }

    const keyDownHandlers: KeyDownHandlers = {
        'Tab': useCallback(e => {
            e.preventDefault()
            editor.insertText('      ')
        }, [editor]),
    }

    return {
        keyDown: (ctrl: boolean) => ctrl ? ctrlKeyDownHandlers : keyDownHandlers,
    }
}