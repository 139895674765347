import { FC, useMemo } from 'react'
import { createEditor } from 'slate'
import { Editable, RenderElementProps, Slate, withReact } from 'slate-react'
import copyStyles from 'Theme/copy.module.css'
import { cn } from 'utils'
import { CodeBlock, Paragraph } from './Elements'
import { useHandlers } from './handlers'
import { Leaf } from './Leaf'
import { Props, Content, JSONContent } from './types'
import s from './styles.module.css'

export const TextEditor: FC<Props> = ({ init = [], onChange }) => {
    const editor = useMemo(() => withReact(createEditor()), [])
    const { keyDown } = useHandlers(editor)

    return (
        <Slate editor={editor} value={init} onChange={value  => {
            const isAstChange = editor.operations.some(op => 'set_selection' !== op.type)
            if (isAstChange) onChange({
                slate: value,
                json: JSON.stringify(value) as JSONContent,
            })
        }}>
            <Editable
                className={cn(copyStyles['copy'], s['text-editor'])}
                renderElement={(props: RenderElementProps) => ({
                    'code': <CodeBlock {...props} />,
                    'paragraph': <Paragraph {...props} />,
                }[props.element.type]) }
                renderLeaf={props => <Leaf {...props} />}
                onKeyDown={e => keyDown(e.ctrlKey)[e.key]?.(e)}
                placeholder="Do the thing over here man!"
            />
        </Slate>
    )
}

export type { Content }
