import { FC } from 'react'
import { useContainer } from '../Container'

export const Loaded: FC = () => {
    const { worldProfile: { world: {
        name,
        description,
        universes,
    } } } = useContainer()

    return (
        <div>
            <header>
                <h1>{name}</h1>
                <p>{description}</p>
            </header>
            <section>
                <header>
                    <h2>Universes</h2>
                </header>
                <ul>
                    {universes.map(universe => (
                        <li key={universe.id}>
                            <h3>{universe.name}</h3>
                            <p>{universe.description}</p>
                        </li>
                    ))}
                </ul>
            </section>
        </div>
    )
}