import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ctx } from './ctx'
import { UseIdentity, UsePrivate, UseCheckRoles } from './types'

export const useIdentity: UseIdentity = () => useContext(ctx)

export const usePrivate: UsePrivate = (allowedRoles = []) => {
    const navigate = useNavigate()
    const { user } = useContext(ctx)

    useEffect(() => {
        if (!user || allowedRoles.some(r => !user.roles.includes(r))) navigate('/', { replace: true })
    }, [navigate, user, allowedRoles])

    return {
        allowed: !!user,
    }
}

export const useCheckRoles: UseCheckRoles = (allowedRoles = []) => {
    const { user } = useContext(ctx)
    return !!user && !allowedRoles.some(r => !user.roles.includes(r))
}
