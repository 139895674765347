export enum QueryStatus {
    Success = 200,
    Error = 400,
    NotFound = 404,
}

export type Query<Input extends Record<string, unknown>, Value extends Record<string, unknown>> = (input: Input) => Promise<(
    | { status: QueryStatus.Error; value: undefined }
    | { status: QueryStatus.NotFound; value: undefined }
    | { status: QueryStatus.Success; value: Value }
)>