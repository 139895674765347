import { Editor, Text, Transforms } from 'slate'
import { isBoldActive, isCodeBlockActive, isItalicActive, isStrikeActive } from './helpers'
import { CustomEditor } from './slate.types'

export const toggleBold = (editor: CustomEditor) => {
    const isActive = isBoldActive(editor)
    Transforms.setNodes(
        editor,
        { bold: isActive ? undefined : true },
        { match: n => Text.isText(n), split: true },
    )
}

export const toggleItalic = (editor: CustomEditor) => {
    console.log('what?')
    const isActive = isItalicActive(editor)
    Transforms.setNodes(
        editor,
        { italic: isActive ? undefined : true },
        { match: n => Text.isText(n), split: true },
    )
}

export const toggleStrike = (editor: CustomEditor) => {
    const isActive = isStrikeActive(editor)
    Transforms.setNodes(
        editor,
        { strike: isActive ? undefined : true },
        { match: n => Text.isText(n), split: true },
    )
}

export const toggleCodeBlock = (editor: CustomEditor) => {
    const isActive = isCodeBlockActive(editor)
    Transforms.setNodes(
        editor,
        { type: isActive ? undefined : 'code' },
        { match: n => Editor.isBlock(editor, n) },
    )
}
