import { makeWorldId, makeWorldName } from 'Domain/Global/World/workshop'
import { createAdapter, makeCollection } from 'Domain/Kit'
import { Data } from '../types'
import { WorldList } from './defs'
import { makeWorldList, makeWorld } from './workshop'

export const worldListAdapter = createAdapter<{ data: Data }, WorldList>(({ data: worlds }) => (
    makeWorldList({
        worlds: makeCollection(worlds.map(({ id, name }) => (
            makeWorld(makeWorldId(id))({
                name: makeWorldName(name),
            })
        ))),
    })
))({ defaultErrorMessage: 'An error occurred in the worldListAdapter' })