import { Logger } from './types'

export const logger: Logger = {
    info: ({ msg, ctx }) => {
        console.groupCollapsed(msg)
        if (ctx) console.log(ctx)
        console.trace()
        console.groupEnd()
    },
    error: ({ error, ctx }) => {
        console.groupCollapsed('ERROR')
        console.error(error)
        if (ctx) console.log(ctx)
        console.groupEnd()
    },
}