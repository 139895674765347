import { useCallback, useEffect, useRef, useState } from 'react'

export const useMountedState = <State extends Record<string, unknown>>(init: State): [State, (fn: (prev: State) => State) => void] => {
    const mounted = useRef(true)
    const [state, set] = useState<State>(init)
    useEffect(() => {
        mounted.current = true
        return () => { mounted.current = false }
    }, [])
    const setState = useCallback<(fn: (prev: State) => State) => void>(fn => (
        mounted.current && set(prev => fn(prev))
    ), [])
    return [state, setState]
}