import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppFooter } from './Footer'
import s from './styles.module.css'
import { AppHeader } from './Header'
import { AppMain } from './Main'
import { IdentityProvider } from 'Adapters/Identity'

export const App: FC = () => (
    <BrowserRouter>
        <IdentityProvider>
            <div className={s['app-layout']}>
                <AppHeader/>
                <AppMain />
                <AppFooter/>
            </div>
        </IdentityProvider>
    </BrowserRouter>
)