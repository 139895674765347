import { FC } from 'react'
import { useIdentity, usePrivate } from 'Adapters/Identity'
import s from './styles.module.css'

export const Account: FC = () => {
    usePrivate()
    const { logout, user } = useIdentity()

    return !user ? null : (
        <div className={s.account}>
            <header>
                <h1>Account</h1>
            </header>
            <div>
                <section>
                    <h2>{ user.name }</h2>
                    <p>{ user.email }</p>
                    <ul>{user.roles.map(role => (
                        <li key={role}>{role}</li>
                    ))}</ul>
                </section>
            </div>
            <footer>
                <button onClick={logout}>Logout</button>
            </footer>
        </div>
    )
}