import dayjs from 'dayjs'
import { Maybe, rmWhite } from './utils'
import { ValidationError, makeValidationError } from './validation'

export type Constraint<Input> = (input: Input) => Maybe<ValidationError>

export type Constrain<Input> = Array<Constraint<Input>>

type MakeConstraint = <Input>(fn: (input: Input) => boolean) => (message: (input: Input) => string) => (input: Input) => Maybe<ValidationError>

export const makeConstraint: MakeConstraint = fn => message => input => fn(input)
    ? undefined
    : makeValidationError(message(input))

export const std = {
    mustBeNanoId: (contextMessage: string) => makeConstraint<string>(
        input => !!input.match(/^[a-zA-Z0-9-_]+$/g)?.length && input.length === 21,
    )(input => rmWhite(`
        ${contextMessage}
        Id must be a valid nanoid, received "${input}"
    `)),
    mustBeUuid: (contextMessage: string) => makeConstraint<string>(
        input => !!input.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i)?.length,
    )(input => rmWhite(`
        ${contextMessage}
        Id must be a valid UUID, received "${input}"
    `)),
    mustBeInt: (contextMessage: string) => makeConstraint<number>(
        input => input % 1 === 0,
    )(input => rmWhite(`
        ${contextMessage}
        Input must be an integer. Received: ${input}
    `)),
    mustBePositive: (contextMessage: string) => makeConstraint<number>(
        input => input >= 0,
    )(input => rmWhite(`
        ${contextMessage}
        Input must be a positive number. Received: ${input}
    `)),
    must_be_between_0_and_1_inclusive: (contextMessage: string) =>
        makeConstraint<number>(input => (
            input >= 0 && input <= 1
        ))(input => rmWhite(`
            ${contextMessage}
            Number must be between 0 and 1 inclusively. Received: ${input}
        `)),
    must_be_greater_than_0_and_less_than_or_equal_to_1: (contextMessage: string) =>
        makeConstraint<number>(input => (
            input > 0 && input <= 1
        ))(input => rmWhite(`
            ${contextMessage}
            Number must be greater that 0 and less than or equal to 1. Received: ${input}
        `)),
    must_be_YYYY_MM_DD: (contextMessage: string) => (
        makeConstraint<string>(input => (
            dayjs(input, 'YYYY-MM-DD').isValid()
        ))(input => rmWhite(`
            ${contextMessage}
            Day string format must be YYYY-MM-DD. Received: ${input}
        `))
    ),
    must_be_between_1_and_80_characters_inclusive: (contextMessage: string) => (
        makeConstraint<string>(input => (
            input.length > 0 && input.length < 81
        ))(input => rmWhite(`
            ${contextMessage}
            Must be between 1 and 80 characters (inclusive). Recieved input length with length ${input.length}.
        `))
    ),
    must_be_between_1_and_240_characters_inclusive: (contextMessage: string) => (
        makeConstraint<string>(input => (
            input.length > 0 && input.length < 240
        ))(input => rmWhite(`
            ${contextMessage}
            Must be between 1 and 240 characters (inclusive). Recieved input length with length ${input.length}.
        `))
    ),
}
