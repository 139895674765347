import { FC } from 'react'
import { IconButton } from 'Comps/Button/Icon'
import s from './styles.module.css'

export const TalesHeader: FC = () => (
    <header className={s['tales-header']}>
        <h1>Tales</h1>
        <div>
            <IconButton name="Sort" />
            <IconButton name="Apps" />
            <IconButton name="Search" />
        </div>
    </header>
)
