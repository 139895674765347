import { FC } from 'react'
import { RenderLeafProps } from 'slate-react'
import { FormattedText } from '../slate.types'
import { TypeStyle } from './types'

const getTypeStyle = (leaf: FormattedText) => {
    if (leaf.bold && leaf.italic && leaf.strike ) return TypeStyle.BoldItalicStrike
    if (leaf.bold && leaf.italic ) return TypeStyle.BoldItalic
    if (leaf.bold && leaf.strike ) return TypeStyle.BoldStrike
    if (leaf.italic && leaf.strike ) return TypeStyle.ItalicStrike
    if (leaf.bold ) return TypeStyle.Bold
    if (leaf.italic ) return TypeStyle.Italic
    if (leaf.strike ) return TypeStyle.Strike
    return TypeStyle.Normal
}

export const Leaf: FC<RenderLeafProps> = ({ attributes, leaf, children }) => {
    const typeStyle = getTypeStyle(leaf)
    return {
        [TypeStyle.BoldItalicStrike]: (
            <strong {...attributes} >
                <em><s>{ children }</s></em>
            </strong>
        ),
        [TypeStyle.BoldItalic]: (
            <strong {...attributes} >
                <em>{ children }</em>
            </strong>
        ),
        [TypeStyle.BoldStrike]: (
            <strong {...attributes} >
                <s>{ children }</s>
            </strong>
        ),
        [TypeStyle.ItalicStrike]: (
            <em {...attributes} >
                <s>{ children }</s>
            </em>
        ),
        [TypeStyle.Bold]: (
            <strong {...attributes} >{ children }</strong>
        ),
        [TypeStyle.Italic]: (
            <em {...attributes} >{ children }</em>
        ),
        [TypeStyle.Strike]: (
            <s {...attributes} >{ children }</s>
        ),
        [TypeStyle.Normal]: (
            <span {...attributes} >{ children }</span>
        ),
    }[typeStyle]
}