import { FC } from 'react'
import { Icon } from 'Comps/Icon'
import buttonStyles from 'Theme/button.module.css'
import { Props } from './types'
import s from './styles.module.css'
import { cn } from 'utils'

export const IconButton: FC<Props> = ({ name, disabled = false }) => (
    <button
        className={ cn(buttonStyles['secondary-button'], s['icon-button'])}
        disabled={disabled}
    >
        <Icon name={name} />
    </button>
)
