import { createContext } from 'react'
import { Ctx } from './types'

export const ctx = createContext<Ctx>({
    user: undefined,
    login: () => {
        console.error('Identity must be accessed from within Provider')
    },
    logout: () => {
        console.error('Identity must be accessed from within Provider')
    },
})
