import { FC } from 'react'
import typeStyles from 'Theme/typography.module.css'
import { NavButton } from 'Comps/Button/Nav'
import { Note } from 'Comps/Note'

const notes = `
### Create World note?
- What's the deal with this world?
`

export const WorldSection: FC = () => (
    <section>
        <header>
            <h2 className={typeStyles['heading-secondary']}>World</h2>
        </header >
        <div>
            <Note content={notes} />
        </div>
        <footer>
            <NavButton
                to="world"
                kind="primary"
                text="Create world"
                iconName="AddCircle"
            />
        </footer>
    </section>
)