import { FC } from 'react'
import { RenderElementProps } from 'slate-react'

export const CodeBlock: FC<RenderElementProps> = ({ attributes, children }) => (
    <pre {...attributes}>
        <code>{children}</code>
    </pre>
)

export const Paragraph: FC<RenderElementProps> = ({ attributes, children }) => {
    return <p {...attributes}>{children}</p>
}