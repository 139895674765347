import { FC, useEffect, useState, ReactNode } from 'react'
import netlifyIdentity from 'netlify-identity-widget'
import { ctx } from './ctx'
import { State, Actions, ValidateUser, Role } from './types'
import { logger } from '../../Mods/Logger'

const { Provider } = ctx

const validateUser: ValidateUser = userData => ({  
    user: userData?.user_metadata?.full_name ? {
        name: userData.user_metadata.full_name,
        email: userData.email,
        roles: userData.app_metadata.roles?.includes('dev') ? [Role.Dev] : [],
    } : undefined,
    errors: userData ? [] : [new Error('Unable to retrieve user data')],
})

export const IdentityProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [
        { initialised, user },
        setState,
    ] = useState<State & { initialised: boolean }>({
        initialised: false,
        user: undefined,
    })

    useEffect(() => {
        netlifyIdentity.init()
        const { user, errors } = validateUser(netlifyIdentity.currentUser())
        errors?.forEach(error => logger.error({ error }))
        setState(user ? {
            initialised: true,
            user,
        } : {
            initialised: true,
            user: undefined,
        })
    }, [setState])

    const actions: Actions = {
        login: () => {
            netlifyIdentity.open()
            netlifyIdentity.on('login', userData => {
                const { user, errors } = validateUser(userData)
                errors?.forEach(error => logger.error({ error }))
                setState(user ? {
                    initialised: true,
                    user,
                } : {
                    initialised: true,
                    user: undefined,
                })
                netlifyIdentity.close()
            })
        },
        logout: () => {
            netlifyIdentity.logout()
            netlifyIdentity.on('logout', () => {
                setState(prev => ({
                    ...prev,
                    isAuthenticated: false,
                    user: undefined,
                }))
            })
        },
    }

    return (
        <Provider value={{ user, ...actions }}>
            {initialised && children}
        </Provider>
    )
}
