import { makeId, makeText, std, validateText } from 'Domain/Kit'

export const makeWorldId = makeId<'world'>()

export const makeWorldName = makeText<'world_name'>(validateText([
    std.must_be_between_1_and_80_characters_inclusive('WorldName'),
]))

export const makeWorldDescription = makeText<'world_description'>(validateText([
    std.must_be_between_1_and_240_characters_inclusive('WorldDescription'),
]))
