export const toDo = `
## To do

- ~~Sort out font~~
- ~~Sort out colour palette~~
- ~~Add basic logging abstraction~~
- ~~Add authentication~~
- ~~Add dev role access to dev area~~
- add "no match" route (404)
- style identity widget
- Investigate events and observation in fauna
- investigate websockets with netlify functions
- Add real logging
`

export const talesContent = `
## Content required for Tales List

- Heading
- TBC
`