import { FC } from 'react'
import { CreateHeader } from './Header'
import s from './styles.module.css'
import { TaleSection } from './Section/Tale'
import { WorldSection } from './Section/World'

export const CreateMenu: FC = () => {

    return (
        <div className={s['create-menu']}>
            <CreateHeader />
            <div>
                <TaleSection />
                <WorldSection />
            </div>
        </div>
    )
}