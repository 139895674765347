export enum ContainerStatus {
    Loading = 'LOADING',
    Loaded = 'LOADED',
    Error = 'ERROR',
}

export type LoadingState = {
    status: ContainerStatus.Loading
    value: undefined
}

export type ErrorState = {
    status: ContainerStatus.Error
    value: undefined
}

export type LoadedState<Value = Record<string, unknown>> = {
    status: ContainerStatus.Loaded
    value: Value
}

export type State<Value = Record<string, unknown>> = LoadingState | ErrorState | LoadedState<Value>

export type ContainerCtx = {

}