import { FC } from 'react'

export const Community: FC = () => {
    
    return <>
        <header>
            <h1>Community</h1>
        </header>
        <div></div>
    </>
}