import { Editor, Element, Node, Text } from 'slate'
import { CustomEditor } from './slate.types'

export const isBoldActive = (editor: CustomEditor) => {
    const [match] = Array.from(Editor.nodes(editor, {
        match: n => Text.isText(n) && n.bold === true,
        universal: true,
    }))

    return !!match
}

export const isItalicActive = (editor: CustomEditor) => {
    const [match] = Array.from(Editor.nodes(editor, {
        match: n => Text.isText(n) && n.italic === true,
        universal: true,
    }))

    return !!match
}

export const isStrikeActive = (editor: CustomEditor) => {
    const [match] = Array.from(Editor.nodes(editor, {
        match: n => Text.isText(n) && n.strike === true,
        universal: true,
    }))

    return !!match
}

export const isCodeBlockActive = (editor: CustomEditor) => {
    const [match] = Array.from(Editor.nodes(editor, {
        match: (n: Node) => Element.isElement(n) && n.type === 'code',
    }))

    return !!match
}
