import { logger } from 'Mods/Logger'
import { Adapter, Invalid, None, SomeDomainValue } from './base'
import { DomainValue, TaggedDate } from './base'

export type Maybe<T> = T | undefined

export type Collection<T> = T[]

export type Result<Target> =
    | { value: Target, errors: Error[] }
    | { value: undefined, errors: Error[] }

export const getDateFromTaggedDate = (taggedDate: TaggedDate<string>): Date => new Date(taggedDate.toString())

export const createAdapter = <Data extends Record<string, unknown>, Output extends DomainValue>(fn: (input: Data) => Result<Output>) => (
    ({ defaultErrorMessage }: { defaultErrorMessage: string }): Adapter<Data, Output> => (
        (input: Data) => {
            try {
                const { value, errors } = fn(input)
                errors.forEach(error => logger.error({ error }))
                return value
            } catch (err) {
                const genericError = new Error(defaultErrorMessage)
                const errors = err instanceof Error ? [genericError, err] : [genericError]
                errors.forEach(error => logger.error({ error }))
                return undefined
            }
        }
    )
)

export const isSome = <T extends DomainValue>(input: T): Maybe<T> => input === '__NONE' ? undefined : input as T

export const isValid = <T extends SomeDomainValue>(input: T | Invalid<T>): Maybe<T> => typeof input === 'object' && '__invalid' in input ? undefined : input as T

export const isSomeValid = <T extends SomeDomainValue>(input: T | None<T> | Invalid<T>): Maybe<T> => input === '__NONE' ? undefined : typeof input === 'object' && '__invalid' in input ? undefined : input as T

export const getSome = <T extends SomeDomainValue>(input: T | None<T> | Invalid<T>): {
    status: 'valid' | 'invalid',
    value: T
} | {
    status: 'none',
    value: undefined
} => input === '__NONE' ? {
    status: 'none',
    value: undefined,
} : typeof input === 'object' && '__invalid' in input ? {
    status: 'invalid',
    value: input.value as T,
} : { status: 'valid', value: input as T }

export const rmWhite = (str: string) => str.replace(/^\s+/gm, '')
