import { Result, Collection } from './utils'
import {
    TaggedDate,
    Text,
    Num,
    ValueObject,
    DomainObject,
    DomainResultObject,
    Id,
    Entity,
    DomainValue,
    ValueObjectInput,
    EntityInput,
    Kind,
    None,
    SomeDomainValue,
    Invalid,
    SomeDomainValueInput,
} from './base'
import {
    validateText,
    validateNum,
    validateDate,
    validateDomainObject,
    validateDay,
} from './validation'
import { std } from './constraints'
import { validateId } from './validation'

export const isResult = <T extends DomainValue | Collection<DomainValue>>(input: T | Result<T>): Result<T> | undefined => (
    Object.keys(input).includes('errors') ? input as Result<T> : undefined
)

export const makeCollection = <T extends DomainValue>(
    collection: Collection<Result<T> | T>,
): Result<Collection<T>> => collection.reduce(
    (acc: Result<Collection<T>>, entry) => {
        const childResult = isResult(entry)
        const value = childResult ? childResult.value : entry as T
        if (value !== undefined) acc.value?.push(value)
        if (childResult?.errors.length) {
            acc.value = undefined
            acc.errors = acc.errors.concat(childResult.errors)
        }
        return acc
    },
    { value: [], errors: [] },
)

const resolveDomainResultObject = <Obj extends DomainObject>(
    obj: DomainResultObject<Obj>,
): Result<Obj> => Object.keys(obj).reduce(
    (acc: Result<Partial<Obj>>, key) => {
        const childResult = isResult(obj[key])
        if (childResult) {
            acc.errors = acc.errors.concat(childResult.errors)
            if (acc.value === undefined || childResult.value === undefined) acc.value = undefined
            else acc.value[key as keyof Obj] = childResult.value
        } else if (acc.value) acc.value[key as keyof Obj] = obj[key] as Obj[string]
        return acc
    },
    { value: {}, errors: [] },
) as Result<Obj>

export const makeText = <Tag extends string>(
    validateInput = validateText<Tag>([]),
) => (input: string): Result<Text<Tag>> => validateInput(input)

export const makeKind = <Tag extends string>(
    validateInput: (input: string) => Result<Kind<Tag>>,
) => (input: string): Result<Kind<Tag>> => validateInput(input)

export const makeNum = <Tag extends string>(
    validateInput = validateNum<Tag>([]),
) => (input: number): Result<Num<Tag>> => validateInput(input)

export const makeId = <Tag extends string>(
    validateInput = validateId<Tag>([std.mustBeNanoId('')]),
) => (input: string): Result<Id<Tag>> => validateInput(input)

export const makeTaggedDate = <Tag extends string>(
    validateInput = validateDate<Tag>([]),
) => (input: Date): Result<TaggedDate<Tag>> => validateInput(input)

export const makeDay = <Tag extends string>(
    validateInput = validateDay<Tag>([std.must_be_YYYY_MM_DD('')]),
) => (input: string): Result<Text<Tag>> => validateInput(input)

export const makeNoneValue = <T extends SomeDomainValue>(): None<T> =>
    '__NONE' as None<T>

export const makeNone = <T extends SomeDomainValue>(): Result<None<T>> => ({
    value: makeNoneValue<T>(),
    errors: [],
})

export const makeInvalidValue = <T extends SomeDomainValue>(input: SomeDomainValueInput<T>): Invalid<T> => ({
    __invalid: '__INVALID',
    value: input,
} as Invalid<T>)

export const makeValueObject = <
    Tag extends string,
    Output extends ValueObject<DomainObject, Tag>
>(
    validateInput = validateDomainObject<ValueObjectInput<Output>>([]),
) => (input: DomainResultObject<ValueObjectInput<Output>>): Result<Output> => {
    const resolvedInput = resolveDomainResultObject(input)
    if (!resolvedInput.value) return resolvedInput as Result<Output>
    return validateInput(resolvedInput.value) as Result<Output>
}

export const makeEntity = <
    Tag extends string,
    Output extends Entity<DomainObject, Tag>
>(
    validateInput = validateDomainObject<EntityInput<Output>>([]),
) => (idResult: Result<Id<Tag>>) => (
    input: DomainResultObject<EntityInput<Output>>,
): Result<Output> => {
    if (!idResult.value) return idResult as Result<Output>

    const resolvedInput = resolveDomainResultObject(input)
    if (!resolvedInput.value) return resolvedInput as Result<Output>

    const validationResult = validateInput(resolvedInput.value)
    if (!validationResult.value) return validationResult as Result<Output>
    const value = validationResult.value as Output
    value.id = idResult.value

    return {
        value,
        errors: [],
    }
}
