import { useIdentity } from 'Adapters/Identity'
import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Icon } from '../../Comps/Icon'
import s from './styles.module.css'

const useCreateDestination = () => {
    const { pathname } = useLocation()
    switch (pathname) {
        case '/tales': return 'create/tale'
        default: return 'create'
    }
}

export const AppFooter: FC = () => {
    const { login, user } = useIdentity()
    const createTo = useCreateDestination()

    return (
        <footer className={s['app-footer']}>
            <nav>
                <NavLink className={s['app-footer-button']} to=".">
                    <Icon name="Community" />
                </NavLink>
                <NavLink className={s['app-footer-button']} to="worlds">
                    <Icon name="Apps" />
                </NavLink>
                { !!user ? (
                    <NavLink className={s['app-footer-button']} to="account" >
                        <Icon name="Account" />
                    </NavLink>
                ) : (
                    <button className={s['app-footer-button']} onClick={login}>
                        <Icon name="Account" />
                    </button>
                )}
                <NavLink className={s['create-link']} to={createTo} >
                    <Icon name="AddCircle" />
                </NavLink>
            </nav>
        </footer>
    )
}
