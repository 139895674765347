import { Icon } from 'Comps/Icon'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import buttonStyles from 'Theme/button.module.css'
import { Props } from './types'
import s from './styles.module.css'

export const NavToParentLid: FC<Props> = ({ text = 'Go back' }) => (
    <div className={s['nav-to-parent-lid']}>
        <NavLink className={buttonStyles['secondary-button']} to="../">
            <Icon name="ArrowBack"/>
            <span>{ text }</span>
        </NavLink>
    </div>
)