import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { cn } from 'utils'
import s from './styles.module.css'
import { Props } from './types'

export const Copy: FC<Props> = ({ content, className }) => (
    <div className={cn(s.copy, className ?? '')}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{ content }</ReactMarkdown>
    </div>
)