import { NavToParentLid } from 'Comps/Lid/NavToParent'
import { FC } from 'react'
import { CreateHeader } from './Header'
import s from './styles.module.css'

export const CreateTale: FC = () => {

    return (
        <div className={s['create-tale']}>
            <NavToParentLid text="Make something else" />
            <CreateHeader />
        </div>
    )
}