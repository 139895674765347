import { usePrivate } from 'Adapters/Identity'
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CreateMenu } from './Menu'
// import { CreateWorld } from './World'
import { CreateTale } from './Tale'

export const Create: FC = () => {
    const { allowed } = usePrivate()

    return allowed ? (
        <Routes>
            <Route path="/">
                <Route index element={<CreateMenu />} />
                <Route path="tale" element={<CreateTale />} />
                {/* <Route path="world" element={<CreateWorld />} /> */}
            </Route>
        </Routes>
    ) : null
}