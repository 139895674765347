import { getWorldProfile } from 'API/Query/GetWorldProfile'
import { WorldProfile } from 'API/Query/GetWorldProfile/domain/defs'
import { QueryStatus } from 'API/Query/types'
import { getErrorState, getLoadedState, getLoadingState } from 'ContainerUtils'
import { ContainerStatus, State } from 'ContainerUtils/types'
import { makeWorldId } from 'Domain/Global/World/workshop'
import { useMountedState } from 'hooks/useMountedState'
import { logger } from 'Mods/Logger'
import { FC, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ctx, Provider } from './ctx'
import { Ctx } from './types'

export const Container: FC<{
    loading: ReactElement
    error: ReactElement
    loaded: ReactElement
}> = ({ loading, error, loaded }) => {
    const { id } = useParams<{ id: string }>()
    const [state, setState] = useMountedState<State<WorldProfile>>(getLoadingState())

    const load = useCallback(async () => {
        if (!id) return getErrorState()
        const { value, errors } = makeWorldId(id)
        if (!value || errors.length) {
            errors.forEach(error => logger.error({ error }))
            setState(() => getErrorState())
            return
        }
        const res = await getWorldProfile({ id: value })
        setState(() => (
            res.status === QueryStatus.Success ? getLoadedState(res.value) : getErrorState()
        ))
    }, [ id, setState ])

    const value = useMemo<Ctx | undefined>(() => state.value && ({
        actions: {},
        worldProfile: state.value,
    }), [ state ])

    useEffect(() => { load() }, [ load ])

    return (
        <Provider value={value}>
            {({
                [ContainerStatus.Loading ]: loading,
                [ContainerStatus.Error ]: error,
                [ContainerStatus.Loaded ]: loaded,
            }[ state.status ])}
        </Provider>
    )
}

export const useContainer = () => {
    const value = useContext(ctx)
    if (!value) throw new Error('Go fuck a pigeon. One of those crusty pidgeons with weird scabby flaps on their beaks.')
    return value
}