import { FC } from 'react'
import typeStyles from 'Theme/typography.module.css'
import { NavButton } from 'Comps/Button/Nav'
import { Note } from 'Comps/Note'

const notes = `
### What might we want to know?
- How many tales there are in total?
- How many open tales there are?
- How many open tales there are that we have contributed to?
- How many open tales there are that we started?
- How many complete tales there are?
- How many complete tales there are that we have contributed to?
- How many complete tales there are that we started?
- Most recently active tales?
`

export const TaleSection: FC = () => (
    <section>
        <header>
            <h2 className={typeStyles['heading-secondary']}>Tall tale</h2>
        </header >
        <div>
            <Note content={notes} />
        </div>
        <footer>
            <NavButton
                to="tale"
                kind="primary"
                text="Create tale"
                iconName="AddCircle"
            />
        </footer>
    </section >
)