import { FC } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { Community } from './Community'
import { Tales } from './Tales'
import { Account } from './Account'
import { RoadMap } from './RoadMap'
import { Create } from './Create'
import { Sandbox } from './Sandbox'
import { WorldList } from './WorldList'
import { WorldProfile } from './WorldProfile'

export const AppMain: FC = () => {
    const location = useLocation()

    return (
        <AnimatePresence exitBeforeEnter>
            <Routes key={location.key} location={location} >
                <Route path="/" element={(
                    <motion.main
                        key={location.key}
                        initial={{ scale: 0.98, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.98, opacity: 0 }}
                        transition={{ duration: 0.1 }}
                        style={{ originX: 0.5, originY: 0 }}
                    ><Outlet /></motion.main>
                )}>
                    <Route index element={<Community/>} />
                    <Route path="worlds">
                        <Route index element={<WorldList/>}/>
                        <Route path=":id" element={<WorldProfile/>}/>
                    </Route>
                    <Route path="tales" element={<Tales/>} />
                    <Route path="account" element={<Account/>} />
                    <Route path="road-map" element={<RoadMap/>} />
                    <Route path="create/*" element={<Create/>} />
                    <Route path="sandbox" element={<Sandbox/>} />
                </Route>
            </Routes>
        </AnimatePresence>
    )
}