import { FC } from 'react'
import s from './styles.module.css'
import { ReactComponent as Apps } from './assets/apps.svg'
import { ReactComponent as Account } from './assets/account_circle.svg'
import { ReactComponent as Community } from './assets/groups.svg'
import { ReactComponent as OrderedList } from './assets/format_list_numbered.svg'
import { ReactComponent as Search } from './assets/search.svg'
import { ReactComponent as Sort } from './assets/sort.svg'
import { ReactComponent as AddCircle } from './assets/add_circle.svg'
import { ReactComponent as ArrowBack } from './assets/arrow_back.svg'

const iconMap = {
    Apps,
    Account,
    Community,
    OrderedList,
    Search,
    Sort,
    AddCircle,
    ArrowBack,
}

export type IconName = keyof typeof iconMap

export const Icon: FC<{ name: IconName}> = ({ name }) => {
    const Comp = iconMap[name]
    return <Comp className={s.icon}/>
}