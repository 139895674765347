import { ContainerStatus, ErrorState, LoadingState, LoadedState } from './types'

export const getLoadingState = (): LoadingState => ({
    status: ContainerStatus.Loading,
    value: undefined,
})

export const getErrorState = (): ErrorState => ({
    status: ContainerStatus.Error,
    value: undefined,
})

export const getLoadedState = <Value extends Record<string, unknown>>(value: Value): LoadedState<Value> => ({
    status: ContainerStatus.Loaded,
    value,
})
