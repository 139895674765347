import { gather } from 'API/utils'
import { QueryStatus } from '../types'
import { worldProfileAdapter } from './domain/adapter'
import { Data, GetWorldProfile } from './types'

export const getWorldProfile: GetWorldProfile = async ({ id }) => {
    const data = await gather<Data>(`get-world-profile/${id}`)
    const value = worldProfileAdapter({ data })

    return value ? {
        status: QueryStatus.Success,
        value,
    } : {
        status: QueryStatus.Error,
        value: undefined,
    }
}