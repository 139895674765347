import { Result } from './utils'
import { Text, Num, DomainObject, TaggedDate, Day } from './base'
import { Constraint } from './constraints'
import { Id, Kind } from './base'

export class ValidationError extends Error {
    constructor(message: string) {
        super(message)
        this.name = 'ValidationError'
    }
}

export const makeValidationError = (message: string): ValidationError =>
    new ValidationError(message)

export const validateText = <Tag extends string>(constraints: Array<Constraint<string>>) => (
    (input: string) => input ? (
        constraints.reduce((acc: Result<Text<Tag>>, constraint) => {
            const error = constraint(input)
            return error ? {
                value: undefined,
                errors: [...acc.errors, error],
            } : acc
        }, { value: input as Text<Tag>, errors: [] })
    ) : (
        { value: undefined, errors: [new ValidationError(`Text validation failed. Recieved falsey input: ${input}`)] }
    )
)

export const validateId = <Tag extends string>(constraints: Array<Constraint<string>>) =>
    (input: string) => constraints.reduce((acc: Result<Id<Tag>>, constraint) => {
        const error = constraint(input)
        return error ? {
            value: undefined,
            errors: [...acc.errors, error],
        } : acc
    }, { value: input as Id<Tag>, errors: [] })

export const validateKind = <Tag extends string>(constraint: Constraint<string>) =>
    (input: string) => {
        const error = constraint(input)
        const result: Result<Kind<Tag>> = error ? {
            value: undefined,
            errors: [error],
        } : {
            value: input as Kind<Tag>, errors: [],
        }
        return result
    }

export const validateNum = <Tag extends string>(constraints: Array<Constraint<number>>) =>
    (input: number) => constraints.reduce((acc: Result<Num<Tag>>, constraint) => {
        const error = constraint(input)
        return error ? {
            value: undefined,
            errors: [...acc.errors, error],
        } : acc
    }, { value: input as Num<Tag>, errors: [] })

export const validateDate = <Tag extends string>(constraints: Array<Constraint<Date>>) =>
    (input: Date) => constraints.reduce((acc: Result<TaggedDate<Tag>>, constraint) => {
        const error = constraint(input)
        return error ? {
            value: undefined,
            errors: [...acc.errors, error],
        } : acc
    }, { value: input as TaggedDate<Tag>, errors: [] })

export const validateDay = <Tag extends string>(constraints: Array<Constraint<string>>) =>
    (input: string) => constraints.reduce((acc: Result<Day<Tag>>, constraint) => {
        const error = constraint(input)
        return error ? {
            value: undefined,
            errors: [...acc.errors, error],
        } : acc
    }, { value: input as Day<Tag>, errors: [] })

export const validateDomainObject = <Obj extends DomainObject>(constraints: Array<Constraint<Obj>>) =>
    (input: Obj) =>
        constraints.reduce((acc: Result<Obj>, constraint) => {
            const error = constraint(input)
            return error ? {
                value: undefined,
                errors: [...acc.errors, error],
            } : acc
        }, { value: input as Obj, errors: [] })
