import { makeWorldDescription, makeWorldName } from 'Domain/Global/World/workshop'
import { createAdapter, makeCollection } from 'Domain/Kit'
import { nanoid } from 'nanoid'
import { makeWorld, makeWorldId } from './World/workshop'
import { makeUniverse, makeUniverseId } from './World/Universe/workshop'
import casual from 'casual-browserify'
import { makeUniverseDescription, makeUniversedName } from 'Domain/Global/World/Universe/workshop'
import { WorldProfile } from './defs'
import { makeWorldProfile } from './workshop'
import { Data } from '../types'

export const worldProfileAdapter = createAdapter<{ data: Data }, WorldProfile>(({ data: [id, name] }) => {
    return makeWorldProfile({
        world: makeWorld(makeWorldId(id))({
            name: makeWorldName(name),
            description: makeWorldDescription(casual.words(25)),
            universes: makeCollection([0, 0, 0].map(() => makeUniverse(makeUniverseId(nanoid()))({
                name: makeUniversedName(casual.words(3)),
                description: makeUniverseDescription(casual.words(25)),
            }))),
        }),
    })
})({ defaultErrorMessage: 'Something broke when trying to adapt a WorldProfile.... go figure.' })
