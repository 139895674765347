import { FC } from 'react'
import { IconButton } from 'Comps/Button/Icon'
import s from './styles.module.css'
import { cn } from 'utils'

export const CreateHeader: FC = () => (
    <header className={cn(s['create-header'])}>
        <h1>Create Tale</h1>
        <div>
            <IconButton name="Sort" />
            <IconButton name="Apps" />
            <IconButton name="Search" />
        </div>
    </header>
)
