import { FC } from 'react'
import { Props } from './types'
import buttonStyles from 'Theme/button.module.css'
import s from './styles.module.css'
import { cn } from 'utils'
import { NavLink } from 'react-router-dom'
import { Icon } from 'Comps/Icon'

export const NavButton: FC<Props> = ({ to, kind = 'secondary', text, iconName, className }) => (
    <NavLink to={to} className={cn(
        kind === 'primary' ? buttonStyles['primary-button'] : buttonStyles['secondary-button'],
        s['nav-button'],
        !text ? s['icon-only'] : '',
        className ?? '',
    )}>
        { text && <span>{ text }</span> }
        { iconName && <Icon name={iconName}/> }
    </NavLink>
)