import { User as NetlifyUser } from 'netlify-identity-widget'

type User = {
    name: string
    email: string
    roles: Role[]
}

export type ValidateUser = (userData: NetlifyUser | null) => {
    user: User | undefined
    errors: Error[]
}

export type Actions = {
    login: () => void
    logout: () => void
}

export type State =
    | {
        user: undefined
    }
    | {
        user: User
    }

export type Ctx = Actions & State

export type UseIdentity = () => Ctx

export type UsePrivate = (allowedRoles?: Role[]) => {
    allowed: boolean
}

export type UseCheckRoles = (allowedRoles?: Role[]) => boolean

export enum Role {
    Dev = 'dev',
    Admin = 'admin',
}