import { getWorldList } from 'API/Query/GetWorldList'
import { WorldList } from 'API/Query/GetWorldList/domain/defs'
import { QueryStatus } from 'API/Query/types'
import { getErrorState, getLoadedState, getLoadingState } from 'ContainerUtils'
import { ContainerStatus, State } from 'ContainerUtils/types'
import { useMountedState } from 'hooks/useMountedState'
import { FC, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react'
import { ctx, Provider } from './ctx'
import { Ctx } from './types'

export const Container: FC<{
    loading: ReactElement
    error: ReactElement
    loaded: ReactElement
}> = ({ loading, error, loaded }) => {
    const [state, setState] = useMountedState<State<WorldList>>(getLoadingState())

    const load = useCallback(async () => {
        const res = await getWorldList({})
        setState(() => (
            res.status === QueryStatus.Success ? getLoadedState(res.value) : getErrorState()
        ))
    }, [ setState ])

    const value = useMemo<Ctx | undefined>(() => state.value && ({
        worldList: state.value,
    }), [ state ])

    useEffect(() => { load() }, [ load ])

    return (
        <Provider value={value}>
            {({
                [ContainerStatus.Loading ]: loading,
                [ContainerStatus.Error ]: error,
                [ContainerStatus.Loaded ]: loaded,
            }[ state.status ])}
        </Provider>
    )
}

export const useContainer = () => {
    const value = useContext(ctx)
    if (!value) throw new Error('Go fuck a pigeon. One of those crusty pidgeons with weird scabby flaps on their beaks.')
    return value
}