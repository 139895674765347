import { FC } from 'react'
import s from './styles.module.css'
import { Role, usePrivate } from 'Adapters/Identity'
import { toDo, talesContent } from './content'
import { Note } from 'Comps/Note'

export const RoadMap: FC = () => {
    usePrivate([Role.Dev ])

    return (
        <div className={s['road-map']}>
            <section>
                <ul>
                    <li><Note content={toDo}/></li>
                    <li><Note content={talesContent}/></li>
                </ul>
            </section>
        </div>
    )
}
