import { FC } from 'react'
import { TaleCard } from './Card'
import { TalesHeader } from './Header'
import s from './styles.module.css'

export const Tales: FC = () => {
    
    return (
        <div className={s.tales}>
            <TalesHeader />
            <div>
                <section>
                    <ul className={s['card-list']}>
                        <li><TaleCard /></li>
                        <li><TaleCard /></li>
                        <li><TaleCard /></li>
                        <li><TaleCard /></li>
                        <li><TaleCard /></li>
                    </ul>
                </section>
            </div>
        </div>
    )
}